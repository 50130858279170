import { useState, useRef } from 'react';
import { getHotelImageVariant } from '../../helpers/property-utils';
import { useTranslation } from 'next-i18next';
import type { GetHotelHomePageQuery } from '../../generated/types';
import Markdown from 'markdown-to-jsx';
import cx from 'classnames';
import { Dialog } from '@dx-ui/osc-dialog';
import { Image } from '../image/Image';
import { BrandLink } from '../brand-buttons/brand-buttons';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

export type TCopyBlock = {
  headline: string;
  paragraphText: string;
  highlightText?: string;
  termsAndConditions?: NonNullable<
    NonNullable<
      NonNullable<GetHotelHomePageQuery['hotel']>['partnerships'][number]['relatedPolicy']
    >['terms']
  >['description'];
  primaryButton: React.ComponentProps<typeof BrandLink> | null;
  secondaryButton?: React.ComponentProps<typeof BrandLink> | null;
  theme: 'light' | 'gray' | 'dark';
  image?: NonNullable<GetHotelHomePageQuery['hotel']>['partnerships'][number]['images'][number];
  logoUrl?: string;
};

export default function CopyBlock({
  headline,
  paragraphText,
  highlightText,
  termsAndConditions,
  primaryButton,
  secondaryButton,
  theme,
  image,
  logoUrl,
  wrapperClass,
}: TWithCustomTheme<TCopyBlock>) {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const imageVariant =
    image && image.variants && getHotelImageVariant(image.variants, rect?.width ?? 0);
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const showTermsAndConditions = !!termsAndConditions?.length;

  return (
    <div
      className={cx(
        {
          'bg-primary': theme === 'dark',
          'bg-bg-alt': theme === 'gray',
        },
        wrapperClass
      )}
    >
      <div className="container">
        <div
          className={cx('py-8 lg:py-10', {
            'grid grid-cols-12 items-start gap-4': !!image,
          })}
        >
          {logoUrl && !image ? (
            <div className="mb-6 sm:mb-0 sm:inline-block sm:w-[200px]" data-testid="no-image-logo">
              <img
                src={logoUrl}
                alt={`Logo for ${headline}`}
                className="max-h-[150px] max-w-[200px]"
              />
            </div>
          ) : null}
          <div
            className={cx('col-span-12 grid grid-cols-6 sm:grid-cols-12 xl:col-span-6', {
              '!inline-block sm:w-[calc(100%-200px)]': !!logoUrl && !image,
            })}
          >
            {logoUrl && image ? (
              <div className="col-span-6 mb-6 sm:col-span-12" data-testid="image-present-logo">
                <img
                  src={logoUrl}
                  alt={`Logo for ${headline}`}
                  className="max-h-[150px] max-w-[200px]"
                />
              </div>
            ) : null}
            <h2
              className={cx('xl:col-span-6 text-start heading-3xl col-span-12 pb-6', {
                '!text-text-inverse': theme === 'dark',
                'lg:col-span-4': !image,
                'lg:col-span-12': !!image,
                'md:col-span-4': !image && !logoUrl,
              })}
            >
              {headline}
            </h2>
            <div
              className={cx('col-span-6 text-start sm:col-span-12', {
                'text-text-inverse': theme === 'dark',
                'md:col-span-8': !image && !logoUrl,
                'lg:col-span-8': !image && !logoUrl,
              })}
            >
              <Markdown>{paragraphText}</Markdown>
              {highlightText ? <div className="mt-4 font-bold">{highlightText}</div> : null}
              {showTermsAndConditions ? (
                <div className="pb-2 pt-6">
                  <button
                    ref={buttonRef}
                    className="text-sm underline"
                    onClick={openModal}
                    type="button"
                  >
                    {t('termsConditions')}
                  </button>
                </div>
              ) : null}
              <div
                className={cx('flex flex-col gap-3 sm:flex-row lg:flex-row', {
                  'sm:!flex-col': !image && !!logoUrl,
                })}
              >
                {primaryButton ? (
                  <div
                    className={cx('flex mt-6 md:w-full lg:w-auto', {
                      'sm:w-1/2': !!secondaryButton,
                      'md:max-w-1/2': !secondaryButton,
                      'sm:!w-full': !image && !!logoUrl,
                    })}
                  >
                    <BrandLink
                      {...primaryButton}
                      anchorClassName={cx(
                        'w-full text-center lg:w-auto',
                        primaryButton?.anchorClassName
                      )}
                    />
                  </div>
                ) : null}
                {secondaryButton ? (
                  <div
                    className={cx('flex sm:w-1/2 md:w-full lg:w-auto m-0 sm:mt-6 lg:mt-6', {
                      'sm:!w-full': !image && !!logoUrl,
                      'md:max-w-1/2': !primaryButton,
                    })}
                  >
                    <BrandLink
                      {...secondaryButton}
                      anchorClassName={cx(
                        'w-full text-center lg:w-auto',
                        {
                          'sm:!mt-0': !image && !!logoUrl,
                        },
                        secondaryButton.anchorClassName
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {imageVariant ? (
            <div
              className="col-span-12 row-start-1 w-full min-w-0 xl:col-span-6 xl:row-start-auto xl:w-auto "
              ref={ref}
            >
              <Image
                alt={image.altText || ''}
                src={imageVariant.url || ''}
                width={rect?.width ?? 0}
                aspectRatio="3:2"
                className="object-cover"
              />
            </div>
          ) : null}
        </div>
      </div>
      {showTermsAndConditions ? (
        <Dialog
          ariaLabel={t('termsConditions')}
          title={t('termsConditions')}
          isOpen={isOpen}
          onDismiss={closeModal}
          size="full"
        >
          <div className="px-4 pt-7">
            {termsAndConditions.map((paragraph) => (
              <div className="pb-4 text-base" key={paragraph.id}>
                <Markdown>{paragraph.value}</Markdown>
              </div>
            ))}
          </div>
        </Dialog>
      ) : null}
    </div>
  );
}
