import cx from 'classnames';
import { BrandIcon } from './brand-icon';
import { getClassnames } from './util/button';
import type { Icon } from '@dx-ui/osc-icon';
import type { Variant } from './util/button';

export interface BrandButtonProps extends Omit<React.ComponentProps<'button'>, 'type'> {
  /** Button label */
  label?: string;
  /** Add Icon props to buttons with Icons */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Button variant */
  variant?: Variant;
}

export const BrandButton = ({
  label,
  variant,
  iconProps,
  brandComponentTheme,
  ...buttonProps
}: BrandButtonProps) => (
  <button
    type="button"
    {...buttonProps}
    className={cx(buttonProps.className, getClassnames({ brandComponentTheme, variant }))}
    data-testid="CTAButtonE2ETest"
  >
    <span className="flex">
      {iconProps ? <BrandIcon {...iconProps} /> : null}
      {label}
    </span>
  </button>
);

export default BrandButton;
