import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import { BrandIcon } from './brand-icon';
import { getClassnames } from './util/button';
import type { Icon } from '@dx-ui/osc-icon';
import type { Variant } from './util/button';

export interface BrandLinkProps extends Link {
  /** Add Icon props to buttons with Icons */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Button variant */
  variant?: Variant;
}

export const BrandLink = ({
  label,
  iconProps,
  brandComponentTheme,
  variant,
  ...linkProps
}: BrandLinkProps) => (
  <Link
    {...linkProps}
    anchorClassName={cx(linkProps.anchorClassName, getClassnames({ brandComponentTheme, variant }))}
    className={linkProps.className || ''}
    isNewWindow={linkProps.isNewWindow || false}
    url={linkProps.url}
    showNewWindowIcon={(linkProps.showNewWindowIcon && linkProps.isNewWindow) || false}
    underline={false}
    data-testid="CTALinkE2ETest"
  >
    {iconProps ? <BrandIcon {...iconProps} /> : null}
    <span>{label}</span>
  </Link>
);

export default BrandLink;
